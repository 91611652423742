import React from 'react'
import './Glitch.css'

export default function GlitchDemo() {
    return (
        <div>
            <div className="glitch glitch--style-1">
                <div className="glitch__img"></div>
                <div className="glitch__img"></div>
                <div className="glitch__img"></div>
                <div className="glitch__img"></div>
                <div className="glitch__img"></div>
            </div>
            <h1 className="text middle" data-text="Glitch Effect">Glitch Effect</h1>
        </div>
    )
}
