import React from 'react'
import { useTranslation } from 'react-i18next'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import GlitchDemo from 'src/components/atoms/GlitchDemo'

export default function About(props) {
    const { t } = useTranslation();

    return (
        <MarketingLayout {...props} title={t('about.title')}>
            <GlitchDemo/>
        </MarketingLayout>
    );
}
